<template>
	<v-card class="create-org-view">
		<v-card-title>
			Verify Organization Information
		</v-card-title>
		<v-card-text>
			<div class="create-org-content">
				<form
					v-on:submit.prevent="searchOrganization"
					v-if="
						!showRegister ||
							!showClaimed ||
							organization.unclaimed_profile
					"
				>
					<ui-label>
						<p
							v-if="this.einError"
							v-bind:style="{ color: 'black', margin: '0px' }"
						>
							{{ this.einError }}
						</p>
						<ui-input
							v-bind:style="{ flex: 4 }"
							v-model="searchString"
							placeholder="Organization Search (Name, EIN)"
						/>
					</ui-label>
					<ui-label class="center">
						<Roundup-button
							v-on:click="searchOrganization"
							:loading="isLoading"
							color="primary"
							class="disabled"
							:disabled="searchString < 2"
							>Find My Organization
						</Roundup-button>
					</ui-label>
				</form>
				<v-form
					v-else
					v-on:submit.prevent="onSubmit"
					lazy-validation
					ref="createForm"
					v-model="createFormValid"
				>
					<ui-label>
						<p
							v-if="this.einError"
							v-bind:style="{ color: 'black', margin: '0px' }"
						>
							{{ this.einError }}
						</p>
						<div
							v-bind:style="{
								display: 'flex',
								flexDirection: 'row'
							}"
						>
							<ui-input
								v-bind:style="{ flex: 4 }"
								v-model="organization.ein"
								placeholder="Organization EIN"
								@input="onlyNumbers($event)"
								autocomplete="off"
							/>
						</div>
					</ui-label>
					<div>
						<ui-label>
							<ui-input
								v-model="organization.name"
								placeholder="Organization Name"
							/>
						</ui-label>
						<ui-label>
							<LocationInput
								ref="location"
								id="map"
								v-model="organization.address"
								placeholder="Organization Address"
								v-on:placechanged="getAddressData"
								country="us"
								autocomplete="false"
								class="roundup-input"
								@focus="organization.address.google = false"
								:initLocation="
									organization.address.formatted_address
								"
							>
							</LocationInput>
						</ui-label>
						<ui-label>
							<ui-input
								v-model="organization.website_url"
								placeholder="Organization Website"
							/>
						</ui-label>
						<div
							style="margin-top: 10px; margin-bottom: 5px"
							class="attention_call_16"
						>
							Category
						</div>
						<v-select
							v-model="organization.category"
							:items="categories.categories"
							item-text="title"
							item-value="id"
							placeholder="Select Your Category"
							:style="{
								padding: '10px',
								color: '#484a50',
								'font-weight': 400,
								'font-size': '16px',
								width: '50%'
							}"
							:rules="[v => !!v || 'Your category is required']"
							required
							@change="cat => getSubCategories(cat)"
							id="categories"
						>
						</v-select>
						<div
							style="margin-bottom: 5px;"
							class="attention_call_16"
						>
							Subcategory
						</div>
						<v-select
							id="subcategories"
							v-model="organization.ntee_id"
							:items="categories.subcategories"
							placeholder="Select Your Subcategory"
							item-text="title"
							item-value="id"
							:disabled="!organization.category"
							:style="{
								padding: '10px',
								color: '#484a50',
								'font-weight': 400,
								'font-size': '16px',
								width: '50%'
							}"
							:rules="[
								v => !!v || 'Your subcategory is required'
							]"
							required
						>
						</v-select>

						<div class="attention_call_16">Impact Statement</div>
						<p>
							Update this sentence with a more specific statement
							of the impact donors will be making for your
							organization:
						</p>
						<p
							style="padding-left: 20px; padding-right: 20px; word-wrap: normal; max-width: 700px; font-weight: 600;"
							class="mt-4"
						>
							Your donation allows us to
							{{ organization.full_impact_statement }}.
						</p>
						<ui-label>
							<ui-input
								v-model="organization.full_impact_statement"
								placeholder="Impact Statement"
								:maxlength="70"
							/>
						</ui-label>
					</div>
					<div
						style="display: inline-block; width: 100%; margin-top: 15px"
					>
						<Roundup-button
							v-bind:style="{
								float: 'right',
								'margin-bottom': '20px'
							}"
							type="submit"
							:disabled="!createFormValid"
							color="primary"
							:loading="isLoading"
							>Next</Roundup-button
						>
						<Roundup-button
							v-bind:style="{
								border: '1px solid #184fd5',
								float: 'right',
								'margin-right': '20px',
								'margin-bottom': '20px',
								marginRight: '25px !important'
							}"
							color="white"
							@click="clearOrg"
							>Back</Roundup-button
						>
					</div>
				</v-form>
				<div v-if="showOrgList">
					<div>
						<div
							v-bind:style="{
								flexDirection: 'row',
								justifyContent: 'space-between',
								display: 'flex',
								marginBottom: '25px'
							}"
						>
							<div
								class="attention_call_16"
								style="margin-top: 20px"
							>
								Select your organization
							</div>
						</div>
						<el-table
							:emptyText="'No results'"
							:data="items"
							@row-click="selectFromList"
							style="width: 100%"
						>
							<el-table-column
								prop="name"
								label="Name"
								width="250px"
							>
							</el-table-column>
							<el-table-column prop="ein" label="ein">
							</el-table-column>
							<el-table-column
								label="Category"
								prop="category"
								min-width="100px"
							>
							</el-table-column>
							<el-table-column prop="city" label="City">
							</el-table-column>
							<el-table-column prop="state" label="State">
							</el-table-column>
						</el-table>

						<Roundup-button
							@click="notOnList"
							color="white"
							class="mt-5"
							>I'm not on the list</Roundup-button
						>
					</div>
				</div>
			</div>
		</v-card-text>
		<modal v-model="alreadyClaimed" size="small">
			<h4>Almost There!</h4>
			<p>
				This organization already has a profile on RoundUp App. Please
				contact the admin that created the profile in order to be added
				to the team.
			</p>
			<p>
				Need more help? Use the chat icon below or email us at
				<a href="mailto:support@roundupapp.com"
					>support@roundupapp.com</a
				>.
			</p>
		</modal>
		<modal v-model="showEditModal" size="large">
			<h4>Glad We Found You!</h4>
			<p>
				The information we have on file is from the IRS database, please
				feel free to edit any information to accurately reflect your
				organization.
			</p>
			<ui-button @click="showEditModal = false">Ok!</ui-button>
		</modal>
		<v-dialog v-model="showLocationDetails" width="500">
			<v-card>
				<v-card-title>
					Please Confirm Your Address
				</v-card-title>

				<v-card-text>
					<v-form ref="confirmForm">
						<v-row>
							<v-text-field
								v-model="organization.address.street_number"
								label="Street Number"
								required
								:rules="[
									() =>
										!!organization.address.street_number ||
										'This field is required'
								]"
							></v-text-field>
							<v-spacer></v-spacer>
							<v-text-field
								v-model="organization.address.street_first"
								label="Street Name"
								required
								:rules="[
									() =>
										!!organization.address.street_first ||
										'This field is required'
								]"
							></v-text-field>
						</v-row>
						<v-row>
							<v-text-field
								v-model="organization.address.city"
								label="City"
								:rules="[
									() =>
										!!organization.address.city ||
										'This field is required'
								]"
								required
							></v-text-field>
							<v-spacer></v-spacer>
							<v-select
								:items="usStates"
								placeholder="Texas"
								item-text="name"
								item-value="code"
								label="State"
								:rules="[
									() =>
										!!organization.address.state ||
										'This field is required'
								]"
								v-model="organization.address.state"
								style="max-width: 212px !important"
							>
							</v-select>
						</v-row>
						<v-row>
							<v-text-field
								style="max-width: 212px !important"
								v-model="organization.address.zip"
								label="Zip Code"
								required
								:rules="[
									() =>
										!!organization.address.zip ||
										'This field is required',
									v =>
										v && v.length !== 5
											? 'Enter a valid zip code'
											: true
								]"
								type="number"
								maxlength="5"
							></v-text-field>
							<v-spacer></v-spacer>
						</v-row>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<Roundup-Button
						color="primary"
						text
						@click="showLocationDetails = false"
					>
						Close
					</Roundup-Button>
					<v-spacer></v-spacer>
					<Roundup-Button color="primary" @click="confirmAddress">
						Confirm
					</Roundup-Button>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { get } from '@/utilities'
import { states } from '@/utilities/enums'
import table from '@/mixins/table'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import { LocationInput } from '../../ui'
import Modal from '../../ui/Modal'
import { analytics } from '@/utilities/tracker'

export default {
	name: 'create-org-view',
	components: { UiButton, UiInput, UiLabel, Screen, Modal, LocationInput },
	mixins: [table],
	props: ['uuid', 'ein', 'skipSearch'],
	mounted() {
		this.organizationPassed()
		this.getCategories()
		window.scrollTo(0, 0)
	},
	data() {
		return {
			isLoading: false,
			createFormValid: false,
			searchString: '',
			organization: {
				name: '',
				ein: '',
				address: {
					street_number: null,
					street_first: null,
					city: null,
					state: null,
					zip: null,
					country: null
				},
				username: '',
				website_url: '',
				full_impact_statement: 'make a difference',
				category: '',
				ntee_id: '',
				keywords: '',
				join_reason: '',
				tos_accept: true,
				unclaimed_profile: 0,
				launch_date_range: ''
			},
			commitment: false,
			einError: null,
			showOrgList: false,
			showRegister: false,
			showSearch: true,
			showClaimed: false,
			alreadyClaimed: false,
			showEditModal: false,
			showLocationDetails: false
		}
	},
	computed: {
		...mapGetters(['categories']),
		formattedAddress() {
			return this.organization.address &&
				this.organization.address.formatted_address
				? this.organization.address.formatted_address
				: ''
		},
		getUsername() {
			return get(this.organization, 'username', '________________')
		},
		deeplink() {
			return 'https://roundup.app/#/' + this.organization.username
		},
		...mapState({
			items: ({ institutionList }) => institutionList.items,
			selectedOrg: ({ institutionList }) => institutionList.selectedOrg,
			searchNtee: ({ institution }) => institution.subcategorySearch,
			total: ({ institutionList }) =>
				institutionList.items && institutionList.items.length
		}),
		usStates() {
			return states
		}
	},
	methods: {
		...mapActions([
			'createInstitution',
			'searchInstitutionByString',
			'searchInstitutionByUUID',
			'getCurrentUser',
			'getCategories',
			'getSubCategories',
			'searchNteeCode'
		]),
		getCategory(inst) {
			try {
				return inst.ntee.category.category_title
			} catch (e) {
				return ''
			}
		},
		organizationPassed() {
			if (this.ein && this.skipSearch) {
				this.isLoading = true
				this.searchInstitutionByString(this.searchString).then(() => {
					this.selectFromList(this.items[0])
				})
				this.isLoading = false
			}
		},
		onSubmit() {
			if (!this.$refs.createForm.validate()) {
				return
			}

			if (!this.organization.address.google) {
				this.showLocationDetails = true
				if (this.$refs.confirmForm) {
					this.$refs.confirmForm.resetValidation()
				}
				return
			}
			this.isLoading = true
			if (!this.organization.tos_accept) {
				this.isLoading = false

				// throw error alert
				this.setAlert({
					alertText:
						'In order to continue, please accept our agreement!',
					showAlert: true,
					alertColor: 'error'
				})
				return
			}
			window.analytics.track('accepted_TOS')
			this.organization.impact_statement = 'Improve Our Community'
			this.organization.call_to_action = 'Signup for RoundUps'
			this.organization.username = this.organization.name.replace(
				/[^A-Z0-9]/gi,
				''
			)
			this.createInstitution({
				...this.organization,
				...this.$route.query
			})
				.then(data => {
					this.getCurrentUser()
						.then(() => {
							this.isLoading = false
						})
						.catch(() => {})
				})
				.catch(({ response }) => {
					this.isLoading = false
					this.setAlert({
						alertText: response.data.message,
						showAlert: true,
						alertColor: 'error'
					})
				})
		},
		searchOrganization(evt) {
			this.isLoading = true
			analytics.track('find-my-nonprofit-button-clicked', {
				ein: this.organization.ein
			})
			this.searchInstitutionByString(this.searchString)
				.then(() => {
					this.isLoading = false
					this.showOrgList = true
				})
				.catch(err => console.log(err))
		},
		onlyNumbers(evt) {
			const clean = evt.replace(/\D+/g, '')
			if (clean !== evt) {
				this.einError = 'Numbers only please :)'
			} else {
				this.einError = null
			}
			this.organization.ein = clean
		},
		notOnList() {
			this.showOrgList = false
			this.showRegister = true
			this.showSearch = false
			this.showClaimed = true
			this.organization = {
				...this.organization,
				name: '',
				address: {
					street_number: null,
					street_first: null,
					city: null,
					state: null,
					zip: null,
					country: null
				},
				id: null,
				uuid: null,
				username: '',
				website_url: '',
				full_impact_statement: 'make a difference',
				category: '',
				ntee_id: '',
				keywords: '',
				join_reason: '',
				tos_accept: true,
				unclaimed_profile: 0,
				launch_date_range: ''
			}
			analytics.track('nonprofit-missing', {
				ein: this.organization.ein
			})
		},
		clearOrg() {
			this.showOrgList = true
			this.showRegister = false
			this.showSearch = true
		},
		selectFromList(org) {
			// take the ein and send it to the database and pull DB data.
			this.isLoading = true
			analytics.track('nonprofit-selected', {
				institution_id: org.id,
				institution_name: org.name
			})

			this.searchInstitutionByUUID(org.uuid)
				.then(() => {
					this.isLoading = false
					org = this.selectedOrg

					this.organization = {
						id: org.id,
						name: org.name,
						ein: org.ein,
						address: org.address || {
							street_number: null,
							street_first: null,
							city: null,
							state: null,
							zip: null,
							country: null
						},
						username: '',
						website_url: '',
						category: null,
						full_impact_statement: 'make a difference',
						call_to_action: '',
						keywords: '',
						join_reason: '',
						tos_accept: true
					}
					this.showClaimed = true
					this.alreadyClaimed = org.unclaimed_profile === 0
					if (org.unclaimed_profile === 1) {
						this.showOrgList = false
					}
					// this.showEditModal = org.unclaimed_profile === 1
					this.showRegister = org.unclaimed_profile === 1
					this.showSearch = false
					if (this.selectedOrg.ntee)
						this.searchNteeCode(this.selectedOrg.ntee.ntee).then(
							() => {
								if (this.searchNtee) {
									this.organization.ntee_id = this.searchNtee.id
									this.organization.category = this.searchNtee.category.id
									this.getSubCategories(
										this.searchNtee.category.id
									)
								}
							}
						)
				})
				.catch(() => {
					this.isLoading = false
				})
		},
		/**
		 * When the location found
		 * @param {Object} addressData Data of the found location
		 * @param {Object} placeResultData PlaceResult object
		 * @param {String} id Input container ID
		 */
		getAddressData(addressData) {
			if (addressData && !addressData.street_number) {
				this.organization.address.google = false
				return
			}
			this.organization.address = addressData
			this.organization.address.google = true
		},
		confirmAddress() {
			if (!this.$refs.confirmForm.validate()) {
				return
			}
			const { address } = this.organization
			address.google = true
			address.formatted_address = `${get(
				address,
				'street_number',
				''
			)} ${get(address, 'street_first', '')} ${get(
				address,
				'city',
				''
			)}, ${get(address, 'state', '')} ${get(address, 'zip', '')}, USA`
			address.full_address = address.formatted_address
			this.$root.$emit('setLocation', address.formatted_address)
			this.organization.address = address

			this.showLocationDetails = false

			this.onSubmit()
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.create-org-view {
	padding: 0px;
	text-align: left;
	min-width: 460px;
	max-width: 720px;

	margin: auto;
	min-height: 700px;

	.v-card__title {
		font-weight: $font-medium;
	}
	.disabled {
		margin-top: 15px !important;
		&:disabled {
			background: $roundup-light-grey;
		}
	}
	.create-org-header {
		position: relative;
		margin: -20px -60px 0px -60px;
		padding: 60px 60px 85px 60px;
		&::before {
			content: '';
			position: absolute;
			background-image: linear-gradient(
				-180deg,
				#d3f9fb 0%,
				#d9f8ed 22%,
				#fff6dd 66%,
				#fff 96%
			);
			width: 100%;
			height: 100%;
			top: 0px;
			left: 0px;
			z-index: -1;
			opacity: 0.5;
		}
		h3 {
			font-family: 'ff-tisa-web-pro';
			font-weight: 400;
			font-size: 24px;
			text-align: center;
			margin-top: 75px;
		}
		p {
			margin: 0px;
			text-align: center;
		}
	}
	.create-org-content {
		padding: 0px 0px;
		h3 {
			text-transform: uppercase;
			font-size: 14px;
			margin-bottom: 0px;
			margin-top: 20px;
			color: #747a8d;
			letter-spacing: 0;
			color: #dcdee3;
		}
		p {
			color: $roundup-medium-grey;
		}
		.checkbox {
			margin: 15px 0px;
		}
	}
}
p {
	color: $roundup-charcoal !important;
}
.attention_call_20 {
	margin-bottom: 20px;
	color: $roundup-charcoal;
	font-size: 20px;
	font-weight: $font-medium;
}
.attention_call_16 {
	color: $roundup-charcoal;
	font-size: 16px;
	font-weight: $font-medium;
}
</style>
